<template>
    <section id="j-inventory">
        <h1 class="main title has-text-centered">Inventory</h1>
        <div class="container">
            <div id="category-list" ref="categoryList" :class="{ banner: accentuateCategories }">
                <ul :style="categoryListMargin">
                    <li :class="{'is-active': selectedCategory === 'all'}" @click="handleCategoryClick('all')">All</li>
                    <li v-for="category in categories" :key="category"
                        :class="{'is-active': selectedCategory === category}"
                        @click="handleCategoryClick(category)">
                        {{ category }}
                    </li>
                </ul>
            </div>
            <transition-group name="update-items" tag="div" id="inventory-items" ref="inventoryItems">
                <weapon v-for="item in filteredItems"
                        class="item"
                        :key="item.name"
                        :name="item.name"
                        :description="item.description"
                        :type="item.type"
                        :fa-icon="item.faIcon"
                        :stats="item.stats">
                </weapon>
            </transition-group>
        </div>
    </section>
</template>

<script>
import items from '../../conf/items.json'
import Weapon from './Weapon'

export default {
  name: 'Inventory',
  components: { Weapon },
  data () {
    return {
      items,
      selectedCategory: 'all',
      accentuateCategories: false
    }
  },
  methods: {
    handleScroll () {
      this.accentuateCategories = this.$refs.inventoryItems.$el.getBoundingClientRect().y < 64 &&
                    this.$refs.inventoryItems.$el.getBoundingClientRect().bottom > 0
    },
    handleCategoryClick (category) {
      window.scrollTo({
        top: this.$refs.inventoryItems.$el.parentElement.getBoundingClientRect().top -
                        document.querySelector('body').getBoundingClientRect().top - 16,
        behavior: 'smooth'
      })
      this.selectedCategory = category
    }
  },
  computed: {
    categories: function () {
      return new Set(this.items.map(item => item.type))
    },
    filteredItems: function () {
      return this.items.filter(item => this.selectedCategory === 'all' || item.type === this.selectedCategory)
    },
    categoryListMargin: function () {
      return {
        marginLeft: this.accentuateCategories
          ? this.$refs.categoryList.offsetWidth / 2 - [...this.$refs.categoryList.querySelectorAll('li')]
            .reduce((previousValue, elem) => previousValue + elem.offsetWidth, 0) / 2 + 'px'
          : 0
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>

    #j-inventory {
        position: relative;
    }

    #inventory-items {
        display: flex;
        flex-wrap: wrap;

        .item {
            margin: 0 0 1rem 1rem;
            transition: transform 1s, opacity 0.3s;
        }
    }

    #category-list {
        transition: border-radius 0.3s ease, top 0.2s ease, width 0.3s ease, background-color 0.3s ease;
        z-index: 1000;
        position: sticky;
        top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
        &.banner {
            top: 0;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            background-color: $background-color-dark;
            width: 100%;
        }
        @include mobile() {
            margin: 0 0 1.5rem 0;
            display: flex;
            justify-content: center;
        }
        > ul {
            transition: margin 0.3s ease, transform 0.3s ease;
            list-style: none;
            display: flex;
            overflow-y: auto;
            > li {
                cursor: pointer;
                padding: 0.75rem;
                transition: color 0.3s ease-in-out;
                &:first-child {
                    padding-left: 0;
                }
                &:not(.is-active) {
                    color: $tabs-link-color;
                }
                &:hover {
                    color: $tabs-link-hover-color;
                }
            }
        }
    }

    .update-items-enter-from, .update-items-leave-to {
        opacity: 0;
        transform: translateX(-30px);
    }
    .update-items-leave-active {
        position: absolute;
    }
</style>
