import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faVuejs, faAngular, faWindows, faLinux, faGit, faGithub, faLinkedin, faAndroid, faApple, faSass, faJava, faPython,
  faWordpress, faJs, faPhp
} from '@fortawesome/free-brands-svg-icons'
import { faTerminal, faCode, faCaretLeft, faCaretDown, faCircle, faExternalLinkAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import anime from 'animejs'
import marked from 'marked'
import moment from 'moment'
import purify from 'dompurify'

import App from '@/App.vue'
import store from '@/store'

library.add(
  faVuejs, faAngular, faWindows, faLinux, faGit, faGithub, faLinkedin, faTerminal, faCode, faCaretLeft, faCaretDown,
  faCircle, faExternalLinkAlt, faAndroid, faApple, faSass, faJava, faPython, faWordpress, faJs, faPhp, faPlus, faMinus
)

const updateWindowSize = () => {
  const size = {
    width: window.innerWidth,
    height: window.innerHeight
  }
  store.commit('updateWindowSize', { size })
}

window.addEventListener('load', updateWindowSize)
window.addEventListener('resize', updateWindowSize)

const app = createApp(App)
app.use(store)

app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$anime = anime
app.config.globalProperties.$marked = marked
app.config.globalProperties.$moment = moment
app.config.globalProperties.$purify = purify

app.mount('#app')
