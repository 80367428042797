<template>
  <div id="allegiances">
    <vueper-slides class="no-shadow ex--center-mode is-white"
                   :dragging-distance="32"
                   :visible-slides="3"
                   arrows-outside
                   bullets-outside
                   @slide="updateSelectedSlide">
      <vueper-slide v-for="allegiance in allegiances" :key="allegiance.name">
        <template v-slot:content>
          <div class="vueperslide__content-wrapper">
            <div class="allegiance-icon">
              <img
                v-if="allegiance.iconIdentifier"
                class="custom-icon"
                :src="allegianceIcons[allegiance.iconIdentifier]"
                :alt="allegiance.name + ' Icon'"/>
              <font-awesome-icon
                v-else-if="allegiance.faIcon"
                :icon="[allegiance.faIcon.prefix, allegiance.faIcon.identifier]"
                size="4x">
              </font-awesome-icon>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
    <div class="details">
      <div class="title">{{ allegiances[slideInFocus].name }}</div>
      <div class="description content" v-html="slideDescription"></div>
      <div class="call-to-action" v-if="allegiances[slideInFocus].url">
        <div>
          <font-awesome-icon icon="external-link-alt"></font-awesome-icon>
        </div>
        <a :href="allegiances[slideInFocus].url" target="_blank" rel="noopener">More Information</a>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import allegiances from '@/conf/allegiances'
import volley from '@/assets/images/allegiances/volley-uster.svg'
import codewars from '@/assets/images/allegiances/codewars.svg'
import rcz from '@/assets/images/allegiances/rcz.svg'

export default {
  name: 'Allegiances',
  components: {
    VueperSlides,
    VueperSlide
  },
  data () {
    return {
      allegiances,
      allegianceIcons: {
        volley,
        codewars,
        rcz
      },
      slideInFocus: 0
    }
  },
  methods: {
    updateSelectedSlide ({ currentSlide }) {
      this.slideInFocus = currentSlide.index
    }
  },
  computed: {
    slideDescription () {
      return this.$marked(this.$purify.sanitize(this.allegiances[this.slideInFocus].description))
    }
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>
#allegiances {
  margin-bottom: 1rem;
  .title {
    font-family: $family-secondary;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .allegiance-icon {
    transition: transform 0.3s ease-in-out;
    pointer-events: none;
    width: 4rem;
    height: 4rem;
    > .custom-icon {
      margin-top: 0.25rem;
      width: 3.5rem;
      height: 3.5rem;
      object-fit: cover;
    }
  }
  .vueperslide {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
    &--active {
      opacity: 1;
      .allegiance-icon {
        transform: scale(1.1);
      }
    }
  }
  ::v-deep(.vueperslides__bullet) {
    border-color: rgba($white, 0.75);
  }
  ::v-deep(.vueperslides__bullet--active) {
    border-color: $white;
  }
  a.icon {
    color: currentColor;
  }
  .ex--center-mode {
    width: 16rem;
    margin: auto;
    &.is-white {
      ::v-deep(.vueperslides__arrow) {
        color: $white;
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 25rem);
    width: 32rem;
    margin: auto;
    .description {
      flex: auto;
    }
    .call-to-action {
      align-self: center;
      cursor: pointer;
      transition: border-radius 0.2s ease, width 0.3s ease, height 0.3s ease, margin 0.3s ease;
      background-color: $background-color-light;
      width: 3rem;
      height: 3rem;
      border-radius: 3rem / 3rem;
      overflow: hidden;
      > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        > svg {
          position: relative;
          left: 0.075rem;
        }
      }
      > a {
        transition: color 0.3s ease-in-out, opacity 0.3s ease-in;
        opacity: 0;
        padding: 0.5rem;
        display: inline-block;
        position: relative;
        bottom: 0.125rem;
        right: 0.5rem;
        color: darken($text, 10%);
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-1;
        &:hover, &:active {
          color: $text;
        }
      }
      &:hover {
        width: 14rem;
        > a {
          opacity: 1;
        }
      }
    }
  }
}
</style>
