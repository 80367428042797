<template>
  <div>
    <div class="header" @click="isExpanded = !isExpanded">
      <span>{{ title }}</span>
      <i class="is-relative">
        <font-awesome-icon
          class="icon"
          :icon="['fas', 'minus']"
          size="sm"
        />
        <transition name="spin">
          <font-awesome-icon
            v-if="!isExpanded"
            class="icon"
            size="sm"
            rotation="90"
            :icon="['fas', 'minus']"
          />
        </transition>
      </i>
    </div>
    <div class="content" :style="{ maxHeight: contentHeight }" ref="wrapper">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  props: {
    title: String
  },
  data () {
    return {
      isExpanded: false,
      contentHeight: '0'
    }
  },
  computed: {
    iconType () {
      return this.isExpanded ? ['fas', 'plus'] : ['fas', 'minus']
    }
  },
  watch: {
    isExpanded (isExpanded) {
      if (isExpanded) {
        const actualHeight = this.$refs.wrapper.scrollHeight
        this.contentHeight = `${actualHeight}px`
      } else {
        this.contentHeight = '0'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.25rem;
  > span {
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-2;
  }
  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.content {
  transition: max-height 0.3s ease;
  overflow: hidden;
  padding: 0.25rem;
}

.icon {
  position: absolute;
}

.spin {
  &-enter-from, &-leave-to {
    transform: rotate(-45deg);
    opacity: 0;
  }
  &-enter-active, &-leave-active {
    transition: all 0.3s ease;
  }
}
</style>
