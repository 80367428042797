<template>
    <div class="j-quest">
        <div class="timeline" ref="timeline">
            <div class="start">
                <span class="mr-2">{{startDate}}</span>
                <font-awesome-icon icon="circle" size="xs"></font-awesome-icon>
            </div>
            <hr>
            <div v-if="endDate" class="end">
                <font-awesome-icon icon="circle" size="xs"></font-awesome-icon>
                <div class="ml-2">{{endDate}}</div>
            </div>
            <div v-else><div class="fade-out"><hr><hr><hr></div></div>
        </div>
        <div class="title">{{title}}</div>
        <div class="details">
            <div v-html="questDescription"></div>
            <ul>
                <li v-for="task in tasks" :key="task">{{task}}</li>
            </ul>
        </div>
        <div class="call-to-action" v-if="url">
            <div>
                <font-awesome-icon icon="external-link-alt"></font-awesome-icon>
            </div>
            <a :href="url" target="_blank" rel="noopener">More Information</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Quest',
  data () {
    return {
      descriptionMarkup: ''
    }
  },
  methods: {
    formatDate: function (date) {
      return date.toLocaleString()
    }
  },
  computed: {
    questDescription () {
      return this.$marked(this.$purify.sanitize(this.descriptionMarkup))
    }
  },
  props: {
    title: String,
    id: String,
    description: String,
    startDate: String,
    endDate: String,
    url: String,
    tasks: Array
  },
  mounted () {
    // String interpolation throws off the linter, so that's that ...
    import('../../conf/quests/' + this.id + '.md').then(quest => {
      this.descriptionMarkup = quest.default ?? ''
    })
  }
}
</script>

<style lang="scss" scoped>

    .j-quest {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 12.125rem);
        .title {
            animation: title 1.25s ease forwards;
            margin: $d-3 0 $d-5 0;
            align-self: center;
        }
        .details {
            animation: slide-in-left 0.5s ease-in both;
            animation-delay: 0.7s;
            flex: auto;
            padding: 0 5vw;
            > ul {
                margin-top: $d-4;
                li::before {
                    content: "•";
                    display: inline-block;
                    width: 1em;
                    color: $primary;
                }
            }
        }
        .call-to-action {
            animation: slide-up 0.4s ease-in both;
            animation-delay: 0.8s;
            cursor: pointer;
            transition: border-radius 0.2s ease, width 0.3s ease, height 0.3s ease, margin 0.3s ease;
            background-color: $background-color-light;
            width: 3rem;
            height: 3rem;
            border-radius: 3rem / 3rem;
            align-self: center;
            overflow: hidden;
            > div {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                > svg {
                    position: relative;
                    left: 0.075rem;
                }
            }
            > a {
                transition: color 0.3s ease-in-out, opacity 0.3s ease-in;
                opacity: 0;
                padding: 0.5rem;
                display: inline-block;
                position: relative;
                bottom: 0.125rem;
                right: 0.5rem;
                color: darken($text, 10%);
                font-size: 0.8em;
                text-transform: uppercase;
                letter-spacing: $letter-spacing-1;
                &:hover, &:active {
                    color: $text;
                }
            }
            &:hover {
                width: 14rem;
                > a {
                    opacity: 1;
                }
            }
        }
    }

    .timeline {
        animation: timeline 1s ease forwards;
        display: flex;
        width: 24rem;
        @include mobile() {
            width: 16rem;
        }
        align-self: center;
        align-items: center;
        > hr {
            flex: auto;
            margin: 0;
        }
        .start, .end {
            display: flex;
            align-items: center;
            font-size: 0.875em;
            font-family: $family-monospace;
        }
        .fade-out {
            display: flex;
            align-items: center;
            > hr {
                margin: 0 0 0 $d-1;
                &:nth-child(1) {
                    width: 0.5rem;
                }
                &:nth-child(2) {
                    width: 0.375rem;
                }
                &:nth-child(3) {
                    width: 0.25rem;
                }
            }
        }
        svg {
            color: $primary;
        }
        hr {
            background-color: $primary;
        }
    }

    @keyframes timeline {
        from {
            transform: translateY(-40px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes title {
        from {
            transform: scale(0.75) translateY(-15px);
            opacity: 0;
        }
        to {
            transform: scale(1) translateY(0);
            opacity: 1;
        }
    }
</style>
