<template>
  <div id="page-content">
    <overlay></overlay>
    <navigation></navigation>
    <home></home>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import Overlay from '@/components/Overlay'
import Home from '@/components/Home'

import 'bubbly-bg'

export default {
  name: 'App',
  components: { Home, Overlay, Navigation },
  data () {
    return {}
  },
  computed: {
    curtain () {
      return this.$store.state.curtain
    }
  },
  watch: {
    curtain (showing) {
      document.documentElement.style.overflow = showing ? 'hidden' : 'auto'
    }
  },
  created () {
    document.documentElement.style.overflow = this.curtain ? 'hidden' : 'auto'
    // eslint-disable-next-line no-undef
    bubbly({
      colorStart: '#1B2529',
      colorStop: '#1B2529',
      bubbles: 7,
      blur: 1,
      compose: 'source-over',
      bubbleFunc: () => `hsla(${183 + Math.random() * 20}, 46%, 49%, 0.4)`,
      radiusFunc: () => Math.random() * 6
    })
  },
  mounted () {
    console.log(`%c
╋┏┓┏┓╋╋╋╋╋╋╋╋╋┏┓╋╋╋╋╋╋╋╋╋╋╋┏┓╋╋╋╋┏┓╋╋╋╋╋╋╋╋╋╋┏┓╋┏━┓╋╋┏┓┏┓╋╋╋╋╋┏━┓
┏┛┗┫┃╋╋╋╋╋╋╋╋┏┛┗┓╋╋╋╋╋╋╋╋╋╋┃┃╋╋╋╋┃┃╋╋╋╋╋╋╋╋╋╋┃┃╋┃┏┛╋╋┃┃┃┃╋╋╋╋╋┃┏┛
┗┓┏┫┗━┳━━┓┏━━╋┓┏╋━━┓┏┳━━┓┏━┛┣━━┳━┫┃┏┓┏━━┳━┓┏━┛┃┏┛┗┳┓┏┫┃┃┃╋┏━━┳┛┗┓┏━━┳━┳━┳━━┳━┳━━┓
╋┃┃┃┏┓┃┃━┫┃━━╋┫┃┃┃━┫┣┫━━┫┃┏┓┃┏┓┃┏┫┗┛┛┃┏┓┃┏┓┫┏┓┃┗┓┏┫┃┃┃┃┃┃╋┃┏┓┣┓┏┛┃┃━┫┏┫┏┫┏┓┃┏┫━━┫
╋┃┗┫┃┃┃┃━┫┣━━┃┃┗┫┃━┫┃┣━━┃┃┗┛┃┏┓┃┃┃┏┓┓┃┏┓┃┃┃┃┗┛┃╋┃┃┃┗┛┃┗┫┗┓┃┗┛┃┃┃╋┃┃━┫┃┃┃┃┗┛┃┃┣━━┃
╋┗━┻┛┗┻━━┛┗━━┻┻━┻━━┛┗┻━━┛┗━━┻┛┗┻┛┗┛┗┛┗┛┗┻┛┗┻━━┛╋┗┛┗━━┻━┻━┛┗━━┛┗┛╋┗━━┻┛┗┛┗━━┻┛┗━━┛
    `, 'color: #00bbbb')
  }
}
</script>

<style lang="scss">
@import "~bulma/bulma.sass";

// add dimens helper classes
@for $i from 1 through length($spacings) {
  $spacing: nth($spacings, $i);

  .p-#{$i} {
    padding: $spacing;
  }

  .px-#{$i} {
    padding-left: $spacing;
    padding-right: $spacing;
  }

  .py-#{$i} {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }

  .m-#{$i} {
    margin: $spacing;
  }

  .mt-#{$i} {
    margin-top: $spacing;
  }

  .mb-#{$i} {
    margin-bottom: $spacing;
  }

  .ml-#{$i} {
    margin-left: $spacing;
  }

  .mr-#{$i} {
    margin-right: $spacing;
  }

  .mx-#{$i} {
    margin-left: $spacing;
    margin-right: $spacing;
  }

  .my-#{$i} {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
}

* {
  box-sizing: border-box;
}

html {
  background-color: $background-color;
}

@each $identifier, $value in $highlight-colors {
  .tag.is-#{$identifier} {
    background-color: $value !important;
  }
  progress[value].progress.is-#{$identifier} {
    &::-webkit-progress-value {
      background-color: $value !important;
    }

    &::-moz-progress-bar {
      background-color: $value !important;
    }

    color: $value !important;
  }
}

.center-frame {
  display: flex;
  justify-content: center;
}

.main.title {
  font-size: 3rem;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;
}

#page-content {
  background-color: inherit;
  transition: background-color 0.5s ease-in-out;
}

::-webkit-scrollbar {
  transition: width 0.2s ease, padding-left 0.2s ease;
  width: 0.375rem;
  padding-left: 0.125rem;

  &:hover {
    width: 0.5rem;
    padding-left: 0;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.25rem $background-color-dark;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 0.5rem;
}

// Animations

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  &-left {
    &-enter-active, &-leave-active {
      transition: opacity .3s, transform .3s;
    }

    &-enter-from {
      opacity: 0;
      transform: translateX(-15px);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(15px);
    }
  }

  &-right {
    &-enter-active, &-leave-active {
      transition: opacity .3s, transform .3s;
    }

    &-enter-from {
      opacity: 0;
      transform: translateX(15px);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(-15px);
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
