<template>
  <section id="j-quest-menu">
    <div class="container">
      <h1 class="main title has-text-centered">Quest Menu</h1>
      <div class="columns">
        <div class="column is-one-fifth">
          <div id="j-quest-types">
            <collapsible title="active">
              <div v-for="quest in openQuests" :key="quest.title"
                   class="j-quest-menu-title"
                   :class="{'is-active': quest === selectedQuest}"
                   @click="selectedQuest = quest">
                <header>{{ quest.title }}</header>
              </div>
            </collapsible>
            <collapsible title="finished">
              <div v-for="quest in finishedQuests" :key="quest.title"
                   class="j-quest-menu-title"
                   :class="{'is-active': quest === selectedQuest}"
                   @click="selectedQuest = quest">
                <header>{{ quest.title }}</header>
              </div>
            </collapsible>
          </div>
        </div>
        <div class="column is-four-quarters">
          <div id="j-quest-container">
            <quest v-if="selectedQuest" :key="selectedQuest.id"
                   :id="selectedQuest.id"
                   :title="selectedQuest.title"
                   :start-date="selectedQuest.startDate"
                   :end-date="selectedQuest.endDate"
                   :url="selectedQuest.url"
                   :tasks="selectedQuest.tasks">
            </quest>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import quests from '../../conf/quests.json'
import Quest from './Quest'
import Collapsible from '@/components/Collapsible'

export default {
  name: 'QuestMenu',
  data () {
    return {
      quests,
      selectedQuest: null
    }
  },
  components: {
    Collapsible,
    Quest
  },
  computed: {
    finishedQuests () {
      return this.quests.filter(quest => quest.finished)
    },
    openQuests () {
      return this.quests.filter(quest => !quest.finished)
    }
  },
  mounted () {
    this.selectedQuest = this.openQuests[0]
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

#j-quest-menu .main.title {
  margin-bottom: 2.25rem;
}

#j-quest-types {
  background-color: $background-color-dark;
  border-radius: 0.5rem;
  padding: 0.5rem;

  ::v-deep(.accordion) { // the vue-bulma-accordion
    > .card {
      background-color: inherit;

      .card-header {
        cursor: pointer;

        .card-header-title, .card-header-icon {
          padding: 0;
        }

        .icon {
          width: 1rem;
          height: 1rem;
        }
      }

      .card-content {
        padding: 0;
      }
    }
  }

  .j-quest-menu-title {
    font-size: 0.875em;
    cursor: pointer;
    padding: 0.25rem 0;
    transition: color 0.3s ease-in-out;
    color: $tabs-link-color;

    > header {
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        transition: width 0.3s ease-in-out, left 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        background-color: $primary;
        opacity: 0.5;
        position: absolute;
        bottom: 0.075rem;
        width: 0;
        left: 50%;
        height: 2px;
      }
    }

    &:hover {
      color: $tabs-link-hover-color;

      > header::after {
        width: 100%;
        left: 0;
      }
    }

    &.is-active {
      color: $tabs-link-hover-color;

      > header::after {
        width: 100%;
        left: 0;
        opacity: 1;
      }
    }
  }
}

#j-quest-container {
  background-color: rgba($background-color-dark, 0.7);
  border-radius: 0.5rem;
  padding: 1.5rem;
  overflow-y: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
