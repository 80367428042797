<template>
  <div id="abilities">
    <div v-for="ability in abilities" class="ability" :key="ability.name">
      <popup :position="isMobile ? 'top' : 'right'">
        <figure class="image is-64x64" :id="ability.name">
          <img class="is-rounded" :src="getAbilityIcon(ability)" :alt="ability.name + ' Icon'">
        </figure>
        <template v-slot:content>
          <div class="popper effects">
            <header>Effects</header>
            <ul class="is-small">
              <li v-for="effect in ability.effects" :key="effect.description">
                <span class="polarity" :class="getEffectPolarity(effect.amount)">{{ effect.amount }}</span>
                <span>{{ effect.description }}</span>
              </li>
            </ul>
          </div>
        </template>
      </popup>
      <div class="ability__name">{{ ability.name }}</div>
    </div>
  </div>
</template>

<script>
import abilities from '@/conf/abilities.json'

import trianglify from 'trianglify'
import Popup from '@/components/Popup'

export default {
  name: 'Abilities',
  components: { Popup },
  data () {
    return {
      abilities: abilities
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    getAbilityIcon (ability) {
      return ability.iconPath ?? trianglify({ width: 256, height: 256, x_colors: 'PuBuGn' }).png()
    },
    getEffectPolarity (value) {
      if (value.startsWith('+')) {
        return 'is-positive'
      } else if (value.startsWith('-')) {
        return 'is-negative'
      } else {
        return 'is-neutral'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#abilities {
  .title {
    font-family: $family-secondary;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  margin-bottom: 1rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .ability {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__name {
      margin-top: 1rem;
      font-size: 0.8em;
      text-transform: uppercase;
      letter-spacing: $letter-spacing-2;
    }

    figure.image {
      cursor: help;
      position: initial;
    }

    .effects {
      pointer-events: none;

      > header {
        font-size: $size-small;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-1;
        margin-bottom: $d-1;
      }

      > ul {
        font-size: $size-small;
      }

      .polarity {
        font-family: $family-monospace;
        margin-right: $d-1;

        &.is-positive {
          color: $positive-color;
        }

        &.is-negative {
          color: $negative-color;
        }

        &.is-neutral {
          color: $grey-dark;
        }
      }
    }
  }
}
</style>
