<template>
    <div class="j-item-container box is-dark">
        <span class="tag" :class="'is-' + colors[type]">{{ type }}</span>
        <div class="cover">
            <font-awesome-icon :icon="[faIcon.prefix, faIcon.identifier]" size="4x"></font-awesome-icon>
        </div>
        <div class="j-title">{{ name }}</div>
        <ul class="j-item-stats">
            <li v-for="(statValue, statName) in stats" :key="statName">
                <div class="j-stat-info">
                    <label>{{ statName }}</label>
                    <div>{{ statValue }}/10</div>
                </div>
                <progress class="progress is-small" :class="'is-' + colors[type]" :value="statValue" max="10"></progress>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Weapon',
  data () {
    return {
      colors: {
        Framework: 'green',
        OS: 'brown',
        Tool: 'gray',
        Language: 'blue'
      }
    }
  },
  props: {
    name: String,
    description: String,
    type: String,
    faIcon: Object,
    stats: Object
  }
}
</script>

<style lang="scss" scoped>

    .j-item-container {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 2em 1em 1em;
        width: 12rem;
        height: 12rem;

        border-radius: 0.5rem;
        overflow: hidden;
        background-color: $background-color-light;

        .tag {
            transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
            position: absolute;
            top: 0;
            right: 0;
            letter-spacing: $letter-spacing-1;
            visibility: hidden;
            opacity: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
        }

        .cover {
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: lighten(#000, 10%);
            background: url('../../assets/images/papyrus-e.png');
            filter: blur(20%);
        }

        &:hover {
            .tag {
                visibility: visible;
                opacity: 1;
            }
            .cover {
                transform: scale(1.5);
                opacity: 0;
            }
        }

        .j-title {
            font-size: 1.5rem;
            font-weight: 600;

        }

        .j-desc {

        }

        .j-item-stats {
            -webkit-font-smoothing: antialiased;
            list-style: none;
            margin: 0;
            padding: 0;
            > li {
                margin-bottom: 0.5rem;
            }
            .j-stat-info {
                color: darken($text, 10%);
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                font-size: 0.625rem;
                font-weight: 600;
                letter-spacing: $letter-spacing-1;
                > label {
                    text-transform: uppercase;
                    letter-spacing: $letter-spacing-2;
                }
            }
        }
    }
</style>
