<template>
    <div id="skills">
        <section>
            <div class="title">Hard Skills</div>
            <div class="skill-item" v-for="skill in hardSkills" :key="skill.name">
                <div class="skill-info">
                    <label>{{ skill.name }}</label>
                    <div>{{ skill.level }}/10</div>
                </div>
                <progress class="progress is-primary is-small" :data-value="skill.level" value="0" max="10"/>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'Skills',
  data () {
    return {
      hardSkills: [
        { name: 'Web Development', level: 8 },
        { name: 'Android Development', level: 7 },
        { name: 'Web Design', level: 5 },
        { name: 'Databases', level: 6 }
      ]
    }
  },
  methods: {
    flexSkills: function () {
      this.$anime({
        targets: '#skills progress',
        value: function (el) {
          return parseInt(el.dataset.value)
        },
        easing: 'easeInOutQuad',
        delay: this.$anime.stagger(400)
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.flexSkills()
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
    #skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include from($desktop) {
            flex-direction: row;
            flex-wrap: wrap;
        }
        > section {
            width: 24rem;
            margin-bottom: 2rem;
        }
        .title {
            font-family: $family-secondary;
            font-weight: 500;
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
        }
        margin-bottom: 1rem;

        .skill-item {
            -webkit-font-smoothing: antialiased;
            max-width: 20rem;
            .skill-info {
                color: darken($text, 10%);
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                font-size: 0.625rem;
                font-weight: 600;
                letter-spacing: $letter-spacing-1;
                > label {
                    text-transform: uppercase;
                    letter-spacing: $letter-spacing-2;
                }
            }
            &:not(:last-of-type) {
                margin-bottom: 0.5rem;
            }
        }
    }
</style>
