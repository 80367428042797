<template>
    <div id="j-intro-overlay" v-if="curtain">
        <div id="action" ref="action">
            <div class="title">CHOOSE CHARACTER</div>
            <figure class="profile image is-64x64"
                    ref="profileImage"
                    @mouseenter="pulsate = false"
                    @mouseleave="pulsate = true"
                    @click="openUp">
                <div class="shadow"></div>
                <div class="ring"></div>
                <div class="ring"></div>
                <img class="is-rounded" src="../assets/images/portrait.jpg" alt="Portrait Image">
            </figure>
        </div>
        <div class="j-half left">
            <div class="divider-line"></div>
        </div>
        <div class="j-half right"></div>
    </div>
</template>

<script>
export default {
  name: 'Overlay',
  data () {
    return {
      pulsate: true,
      pulsateTimeline: null
    }
  },
  methods: {
    openUp: function () {
      this.$anime({
        targets: this.$refs.action,
        easing: 'linear',
        duration: 200,
        opacity: 0
      }).finished.then(() => {
        const actionElem = this.$refs.action
        actionElem.parentElement.removeChild(actionElem)
        const timeline = this.$anime.timeline({
          duration: 1000,
          easing: 'linear'
        })
        timeline
          .add({
            targets: '.divider-line',
            top: 0,
            duration: 700,
            easing: 'easeInOutSine'
          }, 0)
          .add({
            targets: '#j-intro-overlay',
            rotate: '45deg',
            delay: 300,
            duration: 1000
          }, 500)
          .add({
            targets: ['.j-half.left', '.divider-line'],
            translateX: '-100%',
            duration: 1000
          }, 1800)
          .add({
            targets: '.j-half.right',
            translateX: '100%',
            duration: 1000
          }, 1800)
          .finished.then(() => this.$store.commit('updateCurtainState', false))
      })
    }
  },
  computed: {
    curtain () {
      return this.$store.state.curtain
    }
  },
  watch: {
    pulsate (pulsate) {
      if (pulsate) {
        this.pulsateTimeline.loopComplete = null
        this.pulsateTimeline.play()
      } else {
        this.pulsateTimeline.loopComplete = (animation) => animation.pause()
      }
    }
  },
  mounted () {
    if (!this.$refs.profileImage) return
    const image = this.$refs.profileImage.querySelector('img')
    const shadow = this.$refs.profileImage.querySelector('.shadow')
    const rings = this.$refs.profileImage.querySelectorAll('.ring')
    this.pulsateTimeline = this.$anime.timeline({
      easing: 'linear',
      loop: true
    })
    this.pulsateTimeline.add({
      targets: image,
      duration: 1000,
      scale: 1.1
    }, 0).add({
      targets: shadow,
      duration: 1000,
      scale: 1.1,
      translateY: 3
    }, 0).add({
      targets: rings[0],
      duration: 1000,
      opacity: 0,
      scale: 1.5
    }, 0).add({
      targets: rings[1],
      duration: 1000,
      opacity: 0,
      scale: 1.5
    }, 150).add({
      targets: image,
      duration: 1000,
      scale: 1
    }, 1000).add({
      targets: shadow,
      duration: 1000,
      scale: 1,
      translateY: 0
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>

    #action {
        position: fixed;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            letter-spacing: $letter-spacing-1;
            text-align: center;
        }
        .profile.image {
            cursor: pointer;
            position: relative;
            margin-bottom: 1rem;
            > img {
                z-index: 107;
                position: relative;
            }
            > .shadow {
                z-index: 102;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;
                filter: blur(20%);
                background-color: rgba($background-color-dark, 0.3);
            }
            > .ring {
                z-index: 105;
                position: absolute;
                width: 90%;
                height: 90%;
                top: 5%;
                left: 5%;
                border-radius: 50%;
                border: 1px white solid;
            }
        }
    }

    #j-intro-overlay {
        z-index: 1050;
        position: fixed;
        top: -50vh;
        left: -50vw;
        display: flex;
        align-items: center;
        width: 200vw;
        height: 200vh;
        will-change: opacity;
        .j-half {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $background-color;
            width: 50%;
            height: 300vh;
            will-change: transform;
            &.left {
                position: relative;
                align-items: flex-end;
            }
            &.right {
                align-items: flex-start;
            }
        }
        .divider-line {
            z-index: 1055;
            width: 1px;
            height: 300vh;
            background-color: white;
            position: absolute;
            top: -100%;
            right: 0;
        }
    }
</style>
