<template>
  <nav id="j-navigation">
    <div class="menu-item" v-for="entry in menuEntries" :key="entry.name">
      <div class="button is-dark" @click="focusOnSection(entry.sectionId)">
        <span class="icon" :class="{'no-focus': sectionInFocus !== entry.sectionId}">
          <img :src="entry.iconPath" :alt="entry.name + ' icon'"/>
        </span>
      </div>
      <div class="tag is-dark">{{ entry.name }}</div>
    </div>
  </nav>
</template>

<script>
import characterIconPath from '@/assets/images/nav-icons/hooded-figure.svg'
import inventoryIconPath from '@/assets/images/nav-icons/knapsack.svg'
import questIconPath from '@/assets/images/nav-icons/treasure-map.svg'

export default {
  name: 'Navigation',
  data () {
    return {
      menuEntries: [
        { id: 0, sectionId: 'j-character-menu', name: 'Character Menu', iconPath: characterIconPath },
        { id: 1, sectionId: 'j-inventory', name: 'Inventory', iconPath: inventoryIconPath },
        { id: 2, sectionId: 'j-quest-menu', name: 'Quest Menu', iconPath: questIconPath }
      ]
    }
  },
  computed: {
    sectionInFocus () {
      return this.$store.state.sectionInFocus
    },
    sectionOffsets () {
      const result = []
      for (const entry of document.querySelectorAll('main > section')) {
        result.push({
          id: entry.id,
          start: entry.offsetTop,
          end: entry.offsetTop + entry.offsetHeight
        })
      }
      return result
    }
  },
  methods: {
    focusOnSection: function (sectionId) {
      document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' })
    },
    handleScroll () {
      const windowOffset = window.scrollY
      for (const sectionOffset of this.sectionOffsets) {
        if (windowOffset >= sectionOffset.start - 300 && windowOffset < sectionOffset.start + 300) {
          if (this.sectionInFocus !== sectionOffset.id) {
            this.$store.commit('updateSection', sectionOffset.id)
          }
          return
        }
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>

#j-navigation {
  z-index: 100;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  pointer-events: none;

  > .menu-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 1rem 1rem;

    > .tag {
      background-color: rgba(33, 33, 33, 0.5);
      margin-right: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    > .button {
      pointer-events: initial;
      transition: background-color 0.3s ease;

      .icon {
        transition: opacity 0.3s ease;

        &.no-focus {
          opacity: 0.5;
        }
      }

      &:hover {
        .icon {
          opacity: 1;
        }

        & + .tag {
          opacity: 1;
        }
      }
    }
  }
}
</style>
