<template>
  <div style="position: relative;">
    <div
      style="display: flex;"
      @mouseenter="active = true"
      @mouseleave="active = false"
      @click="active = !active"
    >
      <slot/>
    </div>
    <transition name="fade">
      <div
        v-show="active"
        class="tooltip"
        :style="computedStyle"
      >
        <slot name="content"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  data () {
    return {
      active: false
    }
  },
  props: {
    position: {
      type: String,
      default: 'right',
      validator (value) {
        return ['top', 'right', 'bottom', 'left'].includes(value)
      }
    }
  },
  computed: {
    computedStyle () {
      const style = {}
      switch (this.position) {
        case 'top':
          Object.assign(style, {
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: '0 0 0.5rem'
          })
          break
        case 'right':
          Object.assign(style, {
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '0 0 0 0.5rem'
          })
          break
        case 'bottom':
          Object.assign(style, {
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: '0.5rem 0 0'
          })
          break
        case 'left':
          Object.assign(style, {
            right: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '0 0.5rem 0 0'
          })
          break
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  min-width: 16rem;
  border-radius: 0.25rem;
  background-color: rgba(black, 0.9);
  text-align: initial;
  color: inherit;
  border: none;
  padding: 0.5rem;
  z-index: 10;
}
</style>
