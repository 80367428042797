import { createStore } from 'vuex'

export default createStore({
  state: {
    sectionInFocus: 'j-character-menu',
    curtain: true,
    windowSize: {}
  },
  mutations: {
    updateSection: (state, section) => { state.sectionInFocus = section },
    updateCurtainState: (state, showCurtain) => {
      state.curtain = showCurtain
    },
    updateWindowSize: (state, { size }) => {
      state.windowSize = size
    }
  },
  getters: {
    isMobile: state => state.windowSize.width <= 768
  }
})
