<template>
    <main id="j-scroll-view">
        <div id="wip"><div>Early version</div></div>
        <character-menu></character-menu>
        <inventory></inventory>
        <quest-menu></quest-menu>
    </main>
</template>

<script>
import CharacterMenu from './character/CharacterMenu'
import Inventory from './inventory/Inventory'
import QuestMenu from './quest/QuestMenu'

export default {
  name: 'Home',
  components: { QuestMenu, Inventory, CharacterMenu },
  data () {
    return {

    }
  },
  methods: {

  },
  watch: {

  },
  created () {

  }
}
</script>

<style lang="scss">

    #j-scroll-view {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        > section {
            min-height: 100vh;
            padding: 2rem 1rem;
        }
    }

    #wip {
        pointer-events: none;
        position: fixed;
        top: 0;
        width: 100%;
        height: 6rem;
        overflow: hidden;
        z-index: 99999;
        > div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: -2rem;
            top: 5rem;
            transform: rotate(-45deg);
            transform-origin: top left;
            width: 10rem;
            height: 2rem;
            font-size: 9px;
            -webkit-font-smoothing: antialiased;
            letter-spacing: $letter-spacing-1;
            text-transform: uppercase;
            background-color: rgba($primary, 0.5);
            border-top: 1px solid rgba($white, 0.2);
            border-bottom: 1px solid rgba($white, 0.2);
        }
    }

</style>
