<template>
  <section id="j-character-menu">
    <div class="container">
      <h1 class="main title has-text-centered">Character Menu</h1>
      <div class="tabs is-centered">
        <ul>
          <li v-for="tab in tabs" :class="{'is-active': selectedTab === tab}" :key="tab">
            <a @click="changeTab(selectedTab, tab)" class="is-capitalized">{{ tab }}</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <transition :name="'fade-in-' + tabSlideDirection" mode="out-in">
          <section v-if="selectedTab === 'bio'" key="bio">
            <div id="bio">
              <div class="columns">
                <div class="column is-one-third">
                  <div class="profile">
                    <figure class="image is-128x128">
                      <img class="is-rounded" src="../../assets/images/portrait.jpg" alt="Portrait Image">
                    </figure>
                    <div class="details">
                      <dl>
                        <dt>Name</dt>
                        <dd>Jonas Augsburger</dd>

                        <dt>Class</dt>
                        <dd>Nerd</dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="column is-two-thirds">
                  <div v-html="bioText"></div>
                </div>
              </div>
            </div>
          </section>
          <section v-else-if="selectedTab === 'skills'" key="skills">
            <skills/>
          </section>
          <section v-else-if="selectedTab === 'abilities'" key="abilities">
            <abilities/>
          </section>
          <section v-else-if="selectedTab === 'allegiances'" key="allegiances">
            <allegiances/>
          </section>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import Abilities from './Abilities'
import Allegiances from './Allegiances'
import Skills from './Skills'

import bioText from '@/conf/bio.md'

export default {
  name: 'CharacterMenu',
  components: {
    Abilities,
    Allegiances,
    Skills
  },
  data () {
    return {
      introduced: false,
      tabs: ['bio', 'skills', 'abilities', 'allegiances'],
      selectedTab: 'bio',
      tabSlideDirection: 'right'
    }
  },
  methods: {
    changeTab: function (fromTab, toTab) {
      if (fromTab === toTab) return
      this.tabSlideDirection = this.tabs.indexOf(toTab) < this.tabs.indexOf(fromTab) ? 'left' : 'right'
      this.selectedTab = toTab
    }
  },
  computed: {
    focused () {
      return this.$store.state.sectionInFocus === 'j-character-menu'
    },
    bioText () {
      return this.$marked(this.$purify.sanitize(bioText))
    }
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>

#bio, #skills, #allegiances, #character {
  margin-bottom: 1rem;
}

#bio {
  .title {
    font-family: $family-secondary;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  ::v-deep(h3) {
    font-size: 1.25em;
    margin-bottom: 0.25rem;
  }
}

#j-character-menu {
  .tabs {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: $background-color-dark;
    margin-bottom: 0;

    li > a {
      transition: color 0.3s ease;
    }
  }

  .tab-content {
    height: calc(100vh - 12rem);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: rgba($background-color-dark, 0.8);
    padding: 3rem;
    @include mobile() {
      padding: 1.5rem;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include mobile() {
      flex-direction: row;
      align-items: center;
    }

    > .image {
      margin-bottom: 1rem;
      @include mobile() {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }

    > .details {
      dt {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.625em;
        letter-spacing: $letter-spacing-2;
      }

      dd {
        font-size: 0.875em;
        margin-bottom: $d-2;
      }
    }
  }
}

</style>
